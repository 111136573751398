import ApiService from "@/core/services/api.service";
let url = process.env.VUE_APP_API_ADMIN;

class QueryEditor {
  load(id) {
    return ApiService.get(url, "query_editor/" + id);
  }

  loadAll(params) {
    let options = new URLSearchParams(params).toString();
    return ApiService.get(url, "query_editor?" + options);
  }

  store(data) {
    return ApiService.post(url + "/query_editor", data);
  }

  update(id, data) {
    return ApiService.put(url + "/query_editor/" + id, data);
  }

  delete(id) {
    return ApiService.delete(url + "/query_editor/" + id);
  }

  get(id, params) {
    let options = "?page=" + params.page + "&perPage=" + params.size;
    return ApiService.get(url, "data_structure_data/" + id + options);
  }
  getAll(params) {
    let options = "?page=" + params.page + "&perPage=" + params.size;
    if ("order" in params) {
      options += "&orderBy[]=" + params.order;
    }
    return ApiService.get(url, "data_structures" + options);
  }
  getCollectionInfo(id) {
    return ApiService.get(url, "data_structures/" + id);
  }
  runQuery(queryData, params = null) {
    Object.assign(queryData, params ?? {});
    return ApiService.post(url + "/query_editor/run", queryData);
  }
  getDataSetFields(dataSet) {
    return ApiService.get(
      url,
      "/data_structure_queries/" + dataSet + "/fields"
    );
  }
}
export default new QueryEditor();

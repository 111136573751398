import { ref } from "vue";
import { useStore } from "@/core/services/store";

export const useCurrentProjectFilter = () => {
  const store = useStore();

  const filter = ref([
    {
      key: "project_id",
      op: "equals",
      value: store.getters.selectedProject.id
    }
  ]);

  return { filter };
};

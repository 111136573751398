import ApiService from "@/core/services/api.service";
import { BaseService } from "@/components/Tools/baseService";

const url = process.env.VUE_APP_API_ADMIN;

class DataStructures extends BaseService {
  setOptionalHeaders(headers) {
    this.optionalHeaders = headers;
  }

  get(id) {
    return ApiService.get(url, "data_structures/" + id, this.optionalHeaders);
  }

  getAll(params = { noPagination: true }, filter = []) {
    let filterParams = this.getParams(filter, params);
    let options = "?" + new URLSearchParams(params).toString();
    if ("order" in params) {
      options += "&orderBy[]=" + params.order;
    }

    return ApiService.get(
      url,
      "data_structures" + options + filterParams,
      this.optionalHeaders
    );
  }

  getTemplate(id) {
    return ApiService.get(
      url,
      "data_structures/template/" + id,
      this.optionalHeaders
    );
  }

  getFieldTypes() {
    return ApiService.get(
      url,
      "data_structures/field_types",
      this.optionalHeaders
    );
  }

  store(data) {
    return ApiService.post(
      url + "/data_structures",
      data,
      this.optionalHeaders
    );
  }

  update(id, data) {
    return ApiService.put(
      url + "/data_structures/" + id,
      data,
      this.optionalHeaders
    );
  }

  delete(id) {
    return ApiService.delete(
      url + "/data_structures/" + id,
      this.optionalHeaders
    );
  }

  export(id, type) {
    return ApiService.post(url + "/export/" + type + "/dataStructure/" + id);
  }

  import(data) {
    // FormData needs to be sent without a content type
    ApiService.removeHeaderByKey("Content-Type");
    return ApiService.post(url + "/import", data, {}, true);
  }

  analyze(data) {
    ApiService.removeHeaderByKey("Content-Type");
    return ApiService.post(
      url + "/data_structures/analyze",
      data,
      this.optionalHeaders,
      true
    );
  }

  truncate(id) {
    return ApiService.put(
      url + "/data_structures/truncate/" + id,
      this.optionalHeaders
    );
  }

  setClean(id) {
    return ApiService.put(
      url + "/data_structures/set_clean/" + id,
      this.optionalHeaders
    );
  }

  setDirty(id) {
    return ApiService.put(
      url + "/data_structures/set_dirty/" + id,
      this.optionalHeaders
    );
  }

  unlock(id) {
    return ApiService.put(url + "/data_structures/unlock/" + id, {});
  }

  updateQuery(queryData) {
    return ApiService.put(
      url + "/data_structure_queries/" + queryData.id,
      queryData
    );
  }

  storeQuery(queryData) {
    return ApiService.post(url + "/data_structure_queries", queryData);
  }

  deleteQuery(queryId) {
    return ApiService.delete(url + "/data_structure_queries/" + queryId);
  }

  getData(id, params = { page: 1, perPage: 10 }, filter = []) {
    let filterParams = this.getParams(filter, params);
    let options = "?" + new URLSearchParams(params).toString();
    let headers = {};
    return ApiService.get(
      url,
      "data_structure_data/" + id + options + filterParams,
      headers
    );
  }

  updateDataStructureData(dataStructureId, entryId, data) {
    return ApiService.put(
      url + "/data_structure_data/" + dataStructureId + "/" + entryId,
      data,
      this.optionalHeaders
    );
  }

  importData(dataStructureId, data) {
    return ApiService.post(
      url + "/data_structure_data/" + dataStructureId + "/import",
      data,
      this.optionalHeaders
    );
  }

  setEntryDirty(dataStructureId, entryId) {
    return ApiService.post(
      url +
        "/data_structure_data/" +
        dataStructureId +
        "/" +
        entryId +
        "/set_dirty",
      this.optionalHeaders
    );
  }

  setEntryClean(dataStructureId, entryId) {
    return ApiService.post(
      url +
        "/data_structure_data/" +
        dataStructureId +
        "/" +
        entryId +
        "/set_clean",
      this.optionalHeaders
    );
  }

  deleteDataStructureData(dataStructureId, entryId) {
    return ApiService.delete(
      url + "/data_structure_data/" + dataStructureId + "/" + entryId,
      this.optionalHeaders
    );
  }

  massDeleteDataStructureData(dataStructureId, entries) {
    let params = {
      ids: entries
    };

    return ApiService.post(
      url + "/data_structure_data/" + dataStructureId + "/mass_delete",
      params,
      this.optionalHeaders
    );
  }

  exportCsv(params) {
    return ApiService.post(
      url + "/data_structures/export_csv",
      params,
      this.optionalHeaders
    );
  }

  exportDataCsv(dataStructureId, params) {
    return ApiService.post(
      url + "/data_structure_data/" + dataStructureId + "/export_csv",
      params,
      this.optionalHeaders
    );
  }
}

export default new DataStructures();

<script setup>
import FormHelper from "@/components/Tools/FormHelper/FormHelper.vue";
import Progressbar from "@/components/Tools/Progressbar.vue";
import { ref, defineProps, defineEmits } from "vue";

defineProps({
  showSidebar: {
    type: Boolean,
    default: false
  }
});

const emit = defineEmits(["close", "import-data"]);

const formValues = ref({
  data: null
});

const details = ref([
  {
    name: "data",
    type: "code",
    lang: "json",
    style: "dark"
  }
]);

const config = ref({
  labelStacked: true
});

function close() {
  emit("close");
  formValues.value.data = null;
}
</script>

<template>
  <b-sidebar
    id="workflowStartSidebar"
    ref="workflowStartSidebar"
    v-model="showSidebar"
    backdrop
    lazy
    no-close-on-route-change
    no-header
    right
    width="60%"
    @hidden="close"
  >
    <Progressbar
      key="loadingSidebar"
      class="position-relative"
      progress-bar-key="loadingSidebar"
    />
    <div class="px-6 py-4">
      <h3 class="mb-5">
        {{ $t("dataExplorer.importData") }}
      </h3>
      <FormHelper
        key="1"
        v-model="formValues"
        :config="config"
        :form="details"
        class="mt-5"
      />
      <button
        class="btn btn-primary btn-sm ml-1 float-right"
        @click="$emit('import-data', formValues.data)"
      >
        Import
      </button>
    </div>
  </b-sidebar>
</template>

<style lang="scss" scoped>
:deep(.CodeMirror) {
  border-radius: 6px;
  padding: 5px 0;
  min-height: 85vh;
  line-break: anywhere;
  margin: 0.5rem 1rem 2rem 1rem;

  .fullscreen & {
    height: 100vh;
  }
}
</style>

<template>
  <div>
    <TableHelper
      ref="table"
      :actions="actions"
      :enable-filter="false"
      :enable-mass-delete="true"
      :fields="fields"
      :items="data"
      :meta="meta"
      :exportable="true"
      loading-key="loadDataStructureData"
      @reload-data="loadData"
      @show-entry="showEntry"
      @edit-entry="editEntry"
      @delete-entry="deleteEntry"
      @toggle-dirty="toggleDirty"
      @delete-selected-rows="deleteSelectedEntries"
      @export-csv="onExportCsv"
    />
    <b-modal
      id="datasets-explorer-modal"
      v-model="modal.show"
      size="xl"
      :title="modal.title.toString()"
      centered
      scrollable
      @hidden="closeModal"
    >
      <FormHelper
        v-if="typeof modal.data === 'object' && modal.editMode"
        ref="form"
        v-model="modal"
        :config="formConfig"
        :form="formHelperFormEdit"
      />
      <FormHelper
        v-if="typeof modal.data === 'object' && !modal.editMode"
        ref="form"
        v-model="modal"
        :config="formConfig"
        :form="formHelperForm"
      />
      <span v-else-if="typeof modal.data !== 'object'">{{ modal.data }}</span>
      <template
        v-if="typeof modal.data === 'object' && modal.editMode"
        #modal-footer
      >
        <button class="btn btn-primary" @click="updateData">
          {{ $t("general.save") }}
        </button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import DataStructures from "@/components/DataStructures/dataStructures";
import { mapGetters } from "vuex";
import TableHelper from "@/components/Tools/TableHelper/TableHelper.vue";
import {
  addEventToLoadingQueue,
  removeEventFromLoadingQueue
} from "@/composables/useLoadingQueue";
import FormHelper from "@/components/Tools/FormHelper/FormHelper.vue";

export default {
  components: { FormHelper, TableHelper },
  props: {
    dataStructure: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      actions: [
        {
          key: "showEntry",
          icon: "fal fa-magnifying-glass",
          tooltip: this.$t("dataStructures.show"),
          emit: "show-entry"
        },
        {
          key: "editEntry",
          icon: "fal fa-pencil",
          tooltip: this.$t("dataStructures.edit"),
          emit: "edit-entry"
        },
        {
          key: "deleteEntry",
          icon: "fal fa-trash",
          tooltip: this.$t("dataStructures.deleteTitle"),
          emit: "delete-entry"
        },
        {
          key: "toggleProcessedStatus",
          icons: { false: "fal fa-toggle-off", true: "fal fa-toggle-on" },
          tooltips: {
            false: this.$t("dataExplorer.setDirtyDataStructureEntry"),
            true: this.$t("dataExplorer.setCleanDataStructureEntry")
          },
          emit: "toggle-dirty",
          type: "toggle",
          field: "_dirty"
        }
      ],
      // FORMHELPER MODAL
      formHelperForm: [
        {
          type: "code",
          name: "dataString",
          lang: "json",
          disabled: true
        }
      ],
      formHelperFormEdit: [
        {
          type: "code",
          name: "dataString",
          lang: "json"
        }
      ],
      formConfig: {
        snippetPrefix: "preview",
        labelStacked: true,
        enableVariables: false
      },
      fields: [],
      data: [],
      modal: {
        show: false,
        data: undefined,
        dataString: "",
        title: "",
        editMode: false
      },
      // Meta info
      meta: {} ?? { from: 1, to: 1, total: 1 }
    };
  },
  computed: {
    ...mapGetters("route", ["params"])
  },
  mounted() {
    this.getFields();
    this.loadData();
  },
  methods: {
    getFields() {
      let fields = [];
      let sourceFields = this.dataStructure.fields;

      fields.push({
        key: "_primary",
        label: this.data._primary,
        sortable: true
      });

      sourceFields.forEach(field => {
        fields.push({
          key: field.full_name,
          label: field.label ?? field.full_name,
          sortable: true
        });
      });

      fields.push({
        key: "_dirty",
        label: this.data._dirty,
        sortable: true
      });

      fields.push({
        key: "created_at",
        label: this.data.created_at,
        sortable: true,
        type: "datetime"
      });

      fields.push({
        key: "updated_at",
        label: this.data.updated_at,
        sortable: true,
        type: "datetime"
      });

      this.fields = fields;
    },
    loadData() {
      this.entries = [];
      let params = this.params();
      this.loadDataStructureData(params);
    },
    loadDataStructureData(params) {
      addEventToLoadingQueue({ key: "loadDataStructureData" });
      DataStructures.getData(this.dataStructure.id, params, [{}])
        .then(response => {
          this.data = response.data;
          this.meta = response.meta ?? {};
          removeEventFromLoadingQueue({ key: "loadDataStructureData" });
        })
        .catch(error => {
          this.$error(error);
        });
    },
    showEntry(item) {
      this.modal = {
        show: true,
        title: item.item._primary,
        data: item.item,
        dataString: JSON.stringify(item.item, null, "\t"),
        editMode: false
      };
    },
    editEntry(item) {
      this.modal = {
        show: true,
        title: item.item._primary,
        data: item.item,
        dataString: JSON.stringify(item.item, null, "\t"),
        editMode: true
      };
    },
    deleteEntry(item) {
      let id = item.item._id;
      let name = item.item._primary;

      let params = {
        title: this.$t("dataStructures.deleteEntryTitle"),
        text: this.$t("dataStructures.deleteEntryText", { name: name }),
        cancelButtonText: this.$t("general.cancel"),
        confirmButtonText: this.$t("general.delete")
      };

      this.$confirmation(params).then(result => {
        if (!result.isConfirmed) return;
        this.deleteEntryAction(id);
      });
    },
    deleteEntryAction(id) {
      addEventToLoadingQueue({ key: "deleteEntryAction" });

      DataStructures.deleteDataStructureData(this.dataStructure.id, id)
        .then(() => {
          removeEventFromLoadingQueue({ key: "deleteEntryAction" });
          this.loadData();
          this.closeModal();
        })
        .catch(error => {
          this.$error(error);
        });
    },
    deleteSelectedEntries(entries) {
      const ids = entries.map(entry => entry?._id);

      addEventToLoadingQueue({ key: "deleteEntriesAction" });

      DataStructures.massDeleteDataStructureData(this.dataStructure.id, ids)
        .then(() => {
          removeEventFromLoadingQueue({
            key: "deleteEntriesAction",
            type: "success",
            prefix: "dataExplorer",
            name: "massDeleteSuccess"
          });
          this.loadData();
        })
        .catch(error => {
          this.$error(error);
        });
    },
    closeModal() {
      this.modal = {
        show: false,
        data: undefined,
        dataString: undefined,
        title: "",
        editMode: false
      };
    },
    updateData() {
      addEventToLoadingQueue({ key: "updateDataExplorerEntry" });

      const data = JSON.parse(this.modal.dataString);
      const entryId = this.modal.data._id;

      delete data._id;

      DataStructures.updateDataStructureData(
        this.dataStructure.id,
        entryId,
        data
      )
        .then(() => {
          removeEventFromLoadingQueue({ key: "updateDataExplorerEntry" });
          this.loadData();
          this.closeModal();
        })
        .catch(error => {
          this.$error(error);
        });
    },
    toggleDirty(item) {
      addEventToLoadingQueue({ key: "toggleDirtyEntry" });

      if (item.item._dirty) {
        DataStructures.setEntryClean(this.dataStructure.id, item.item._id)
          .then(() => {
            removeEventFromLoadingQueue({ key: "toggleDirtyEntry" });
            this.loadData();
          })
          .catch(error => {
            removeEventFromLoadingQueue({ key: "toggleDirtyEntry" });
            this.$error(error);
          });
      } else {
        DataStructures.setEntryDirty(this.dataStructure.id, item.item._id)
          .then(() => {
            removeEventFromLoadingQueue({ key: "toggleDirtyEntry" });
            this.loadData();
          })
          .catch(error => {
            removeEventFromLoadingQueue({ key: "toggleDirtyEntry" });
            this.$error(error);
          });
      }
    },
    onExportCsv(params) {
      DataStructures.exportDataCsv(this.dataStructure.id, params)
        .then()
        .catch(error => {
          this.$error(error);
        });
    }
  }
};
</script>

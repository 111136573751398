<template>
  <div>
    <Header :title="$t('dataExplorer.dataExplorerResources')" />
    <TableHelper
      ref="table"
      :items="entries"
      :meta="meta"
      :fields="fields"
      :actions="actions"
      :enable-filter="false"
      default-order-by="label"
      loading-key="loadDataStructures"
      @reload-data="loadData"
      @open-details="openDetails"
      @open-editor="openEditor"
      @open-import-data="importDataModal"
      @truncate-data-structure="truncateDataStructure"
      @clean-data-structure="cleanDataStructure"
      @dirty-data-structure="dirtyDataStructure"
      @import-data="importDataRequest"
    ></TableHelper>
    <DataImportSidebar
      :show-sidebar="showImportDataModal"
      @import-data="importDataRequest"
    ></DataImportSidebar>
  </div>
</template>

<script>
import DataStructures from "@/components/DataStructures/dataStructures";
import QueryEditor from "@/components/DataExplorer/queryEditor";
import { mapGetters } from "vuex";
import Header from "@/components/Tools/Header/Header.vue";
import TableHelper from "@/components/Tools/TableHelper/TableHelper.vue";
import {
  addEventToLoadingQueue,
  removeEventFromLoadingQueue
} from "@/composables/useLoadingQueue";
import { useCurrentProjectFilter } from "@/composables/useCurrentProjectFilter";
import DataImportSidebar from "@/components/DataExplorer/DataImportSidebar.vue";

export default {
  components: { DataImportSidebar, TableHelper, Header },
  props: {},
  data() {
    return {
      entries: [],
      showImportDataModal: false,
      selectedDataStructure: null,
      actions: [
        {
          key: "options",
          icon: "fal fa-ellipsis-v",
          children: [
            {
              key: "openDetails",
              label: this.$t("dataExplorer.openDetails"),
              emit: "open-details"
            },
            {
              key: "importData",
              label: this.$t("dataExplorer.importData"),
              emit: "open-import-data"
            },
            {
              key: "openEditor",
              label: this.$t("dataExplorer.editDataStructure"),
              emit: "open-editor"
            },
            {
              key: "truncateDataStructure",
              label: this.$t("dataExplorer.truncateDataStructure"),
              emit: "truncate-data-structure",
              condition: {
                field: "owner_type",
                operator: "!==",
                value: "DataSet"
              }
            },
            {
              key: "cleanDataStructure",
              label: this.$t("dataExplorer.setCleanDataStructure"),
              emit: "clean-data-structure",
              condition: {
                field: "owner_type",
                operator: "!==",
                value: "DataSet"
              }
            },
            {
              key: "dirtyDataStructure",
              label: this.$t("dataExplorer.setDirtyDataStructure"),
              emit: "dirty-data-structure",
              condition: {
                field: "owner_type",
                operator: "!==",
                value: "DataSet"
              }
            }
          ]
        }
      ],
      fields: [
        {
          key: "label",
          label: this.$t("table.label"),
          sortable: true
        },
        {
          key: "owner_type",
          label: this.$t("table.source"),
          sortable: true,
          type: "sourceIcon"
        },
        {
          key: "data_entry_count",
          label: this.$t("dataExplorer.entriesCount"),
          sortable: false
        },
        {
          key: "dirtyRecords",
          label: this.$t("dataExplorer.dirtyEntriesCount"),
          sortable: false,
          type: "dirtyRecords"
        },
        {
          key: "data_last_modified_at",
          label: this.$t("dataExplorer.dataLastUpdated"),
          sortable: false,
          type: "datetime"
        }
      ],
      // Meta info
      meta: {}
    };
  },
  computed: {
    ...mapGetters("route", ["params"]),
    ...mapGetters(["selectedProject"])
  },
  watch: {
    selectedProject: function () {
      this.loadData();
    }
  },
  mounted() {
    this.loadData();
  },
  methods: {
    loadData() {
      this.entries = [];
      let params = this.params();
      this.loadDataStructures(params);
    },
    loadDataStructures(params) {
      addEventToLoadingQueue({ key: "loadDataStructures" });

      const { filter } = useCurrentProjectFilter();
      DataStructures.getAll(params, filter.value)
        .then(response => {
          this.entries = response.data;
          this.meta = response.meta;
          removeEventFromLoadingQueue({ key: "loadDataStructures" });
        })
        .catch(error => {
          this.$error(error);
        });
    },
    loadDataSets(params) {
      addEventToLoadingQueue({ key: "loadDataSets" });
      QueryEditor.loadAll(params)
        .then(response => {
          this.entries = response.data;
          this.meta = response.meta;
          removeEventFromLoadingQueue({ key: "loadDataSets" });
        })
        .catch(error => {
          this.$error(error);
        });
    },
    openDetails(item) {
      this.$router.push({
        name: "projectDataExplorerDetails",
        params: { id: item.item.id }
      });
    },
    openEditor(item) {
      if (item.item.owner_type.includes("DataSet")) {
        this.$router.push({
          name: "projectDataSetsEditor",
          params: { id: item.item.owner_id }
        });
      } else {
        this.$router.push({
          name: "projectDataStructuresEditor",
          params: { id: item.item.id }
        });
      }
    },
    importDataRequest(data) {
      addEventToLoadingQueue({
        key: "importDataStructureData"
      });
      DataStructures.importData(
        this.selectedDataStructure.item.id,
        JSON.parse(data)
      )
        .then(response => {
          this.showImportDataModal = false;

          let success = response.success ? "Success" : "Error";
          this.$toast.fire({
            icon: success.toLowerCase(),
            title: this.$t("dataExplorer.importData" + success)
          });
          this.loadData();
        })
        .catch(errors => {
          this.showImportDataModal = false;
          this.$error(errors);
        })
        .finally(() => {
          removeEventFromLoadingQueue({
            key: "importDataStructureData"
          });
        });
    },
    truncateDataStructure(item) {
      let params = {
        title: this.$t("dataExplorer.truncateTitle"),
        text: this.$t("dataExplorer.truncateText", {
          name: item.item.label
        }),
        cancelButtonText: this.$t("dataExplorer.cancel"),
        confirmButtonText: this.$t("dataExplorer.confirm")
      };

      this.$confirmation(params).then(result => {
        if (!result.isConfirmed) return;
        this.truncateDataStructureAction(item);
      });
    },
    importDataModal(item) {
      this.showImportDataModal = true;
      this.selectedDataStructure = item;
    },
    truncateDataStructureAction(item) {
      addEventToLoadingQueue({ key: "truncateDataStructureAction" });
      DataStructures.truncate(item.item.id)
        .then(() => {
          this.loadData();
          removeEventFromLoadingQueue({
            key: "truncateDataStructureAction",
            type: "success",
            prefix: "dataSets",
            name: "deletedSuccess"
          });
        })
        .catch(error => {
          this.$error(error);
        });
    },
    cleanDataStructure(item) {
      let params = {
        title: this.$t("dataExplorer.setCleanTitle"),
        text: this.$t("dataExplorer.setCleanText", {
          name: item.item.label
        }),
        cancelButtonText: this.$t("dataExplorer.cancel"),
        confirmButtonText: this.$t("dataExplorer.confirm")
      };

      this.$confirmation(params).then(result => {
        if (!result.isConfirmed) return;
        this.cleanDataStructureAction(item);
      });
    },
    cleanDataStructureAction(item) {
      addEventToLoadingQueue({ key: "cleanDataStructureAction" });
      DataStructures.setClean(item.item.id)
        .then(() => {
          this.loadData();
          removeEventFromLoadingQueue({
            key: "cleanDataStructureAction",
            type: "success",
            prefix: "dataExplorer",
            name: "setCleanSuccess"
          });
        })
        .catch(error => {
          this.$error(error);
        });
    },
    dirtyDataStructure(item) {
      let params = {
        title: this.$t("dataExplorer.setDirtyTitle"),
        text: this.$t("dataExplorer.setDirtyText", {
          name: item.item.label
        }),
        cancelButtonText: this.$t("dataExplorer.cancel"),
        confirmButtonText: this.$t("dataExplorer.confirm")
      };

      this.$confirmation(params).then(result => {
        if (!result.isConfirmed) return;
        this.dirtyDataStructureAction(item);
      });
    },
    dirtyDataStructureAction(item) {
      addEventToLoadingQueue({ key: "dirtyDataStructureAction" });
      DataStructures.setDirty(item.item.id)
        .then(() => {
          this.loadData();
          removeEventFromLoadingQueue({
            key: "dirtyDataStructureAction",
            type: "success",
            prefix: "dataExplorer",
            name: "setDirtySuccess"
          });
        })
        .catch(error => {
          this.$error(error);
        });
    }
  }
};
</script>
